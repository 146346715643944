import Vue from 'vue'
import VueI18n from 'vue-i18n'

import zh from '@/lang/i18n/zh.json'
import en from '@/lang/i18n/en.json'
import ja from '@/lang/i18n/ja.json'

Vue.use(VueI18n)

const locale = 'zh'

const messages = {
  zh: zh,
  en: en,
  ja: ja
}

const i18n = new VueI18n({
  locale,
  messages
})

export default i18n
