<template>
  <div class="loading" v-show="show" id="Loading">
    <div class="lds-ripple">
      <div></div>
      <div></div>
    </div>
    <div v-if="text" class="loading-text text-center">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: ['show'],
  computed: {
    text () {
      return this.$store.state.loadingText
    }
  }
}
</script>

<style lang="scss">
.loading {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(#fff, 0.9);
  z-index: 99999;
  &-text {
    width: 90%;
    position: absolute;
    top: 20%;
    left: 50%;
    font-size: 20px;
    font-weight: 500;
    color: $primary;
    transform: translateX(-50%);
    z-index: 99999;
  }
}
.lds-ripple {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  div {
    position: absolute;
    border: 4px solid $primary;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>
